import React                    from "react";
import { FC }                   from "react";
import { useContext }           from "react";
import { useEffect }            from "react";
import { useMemo }              from "react";
import { useCallback }          from "react";
import { FormSpy }              from "@relcu/form";
import { JsonField }            from "@relcu/ui";
import { useConstant }          from "@relcu/ui";
import { Field }                from "@relcu/form";
import { DateField }            from "@relcu/ui";
import { NumberField }          from "@relcu/ui";
import { TextField }            from "@relcu/ui";
import { ChoiceField }          from "@relcu/ui";
import { SwitchField }          from "@relcu/ui";
import { FormWhere }            from "@relcu/ui";
import { Box }                  from "@relcu/ui";
import { layoutVar }            from "../../../../reactiveVars";
import { getField }             from "../../../../utils/schemaUtils";
import { useSearchPermissions } from "../../../AccessControl/useSearchPermision";
import { LeadMembersContext }   from "../../Form/LeadForm";
import { ContactField }         from "./ContactField";

export const LeadMemberForm: FC<{ name: string, additionalFields?: { name: string }[]}> = React.memo(function LeadMemberForm({ name, additionalFields}) {
  const layout = layoutVar();
  const { canSearch } = useSearchPermissions();
  const isSearchable = canSearch("Contact");
  const validateContact = useCallback((value, { members }, { name }) => {
    const index = name.match(/(\d+)/)[ 0 ];
    const others = members.filter((_, i) => i != index);
    const type = members[ index ]?.type;
    return others?.some((el) => (el.contact?.id == members[ index ]?.contact?.id
        && (el.type == type || (["borrower"].includes(el.type) && ["borrower"].includes(type)))))
      && {"id":"This contact already exists with a similar relation to the lead."};
  }, []);
  const { editableMemberIndexVar } = useContext(LeadMembersContext);
  const visibleContactFields = useConstant(() => {
    return additionalFields ? additionalFields.map(el => el.name).concat(["firstName", "lastName", "middleName", "emails", "phones"]) : ["firstName", "lastName", "middleName", "emails", "phones"];
  });
  const contactFields = useMemo(() => layout.Contact.dialog.sections[ 0 ].fields.filter((f) => visibleContactFields.includes(f.name)), [visibleContactFields, layout.Contact.dialog.sections[ 0 ].fields]);
  useEffect(() => {
    return () => {
      editableMemberIndexVar(null);
    };
  }, []);

  return <Box flex={1} container wrap={"wrap"} gap={"XXS"}>
    <Field name={`${name}.type`}>
      {({ input, meta }) => (
        <ChoiceField options={getField("LeadMember", "type")[ "options" ]} flexBasis={isSearchable ? "50%" : "100%"}
                     required={true}
                     placeholder={"Select relation with the lead"}
                     label={"Relation to the lead"} name={`${name}.type`}/>)}
    </Field>
    {
      !!isSearchable &&
      <ContactField
        // onChange={(value) => {
        //   const fields = {};
        //   contactFields.forEach((contactField, index) => {
        //     fields[ contactField.name ] = value[ contactField.name ];
        //   });
        //
        //   if (value.types) {
        //     fields["type"] = value.types[0]
        //   }
        //   form.change(`${name}.contact`, fields);
        // }}
        required={false}
        flexBasis={"calc(50% - 8px)"}
        placeholder={"Select contact"}
        validate={validateContact}
        name={`${name}.contact`}
        label={"Contact"}
        targetClass={"Contact"}
        halfSize={true}
      />
    }
    <FormWhere conditions={{ and: [{ path: `${name}.type`, operator: "equals", value: "custom" }] }}>
      <TextField required={true} name={`${name}.typeElaborate`} label={"Relation name"}
                 placeholder={"Type custom relation name"}
                 flexBasis={"100%"}/>
    </FormWhere>
    {
      contactFields.map((field, index) => {
          const { defaultValue, ...rest } = field;
          return <JsonField
            key={index}
            {...rest}
            mode={"edit"}
            name={name ? [`${name}.contact`, field.name].join(".") : field.name}
            view={"write"}
          />;
        }
      )
    }
    <FormSpy
      subscription={{ pristine: true }}
      onChange={({ pristine }) => !pristine}/>
    <SwitchField flexBasis={"calc(50% - 8px)"} placeholder={"Mark as primary"} name={`${name}.isPrimary`}
                 label={"Primary contact"}/>
    <FormWhere conditions={{ and: [{ path: `${name}.type`, operator: "in", value: ["borrower", "co_borrower"] }] }}>
      <ChoiceField options={getField("LeadMember", "employmentStatus")[ "options" ]}
                   label={"Employment status"}
                   flexBasis={"calc(50% - 8px)"}
                   placeholder={"Select employment status"}
                   name={`${name}.employmentStatus`}/>
      <NumberField view={"write"} name={`${name}.creditScore`} placeholder={"Enter credit score"} label="Credit score"
                   flexBasis={"calc(50% - 8px)"}/>
      <SwitchField name={`${name}.isMilitary`} label="Is military" flexBasis={"calc(50% - 8px)"}/>
      <NumberField view={"write"} name={`${name}.monthlyIncome`} label="Monthly income"
                   placeholder="Enter monthly income"
                   flexBasis={"calc(50% - 8px)"}/>
      <ChoiceField options={getField("LeadMember", "creditRating")[ "options" ]}
                   label={"Credit rating"}
                   flexBasis={"calc(50% - 8px)"}
                   placeholder={"Select credit rating"}
                   name={`${name}.creditRating`}/>
      <SwitchField name={`${name}.bankruptcy`} label="Is bankrupt" flexBasis={"calc(50% - 8px)"}/>
      <TextField name={`${name}.foreclosure`} label="Foreclosure" flexBasis={"calc(50% - 8px)"}/>
      <TextField name={`${name}.maritalStatus`} label="Marital status" flexBasis={"calc(50% - 8px)"}/>
      <FormWhere
        conditions={{ and: [{ path: `${name}.employmentStatus`, operator: "in", value: ["self_employed", "not_self_employed"] }] }}>
        <DateField flexBasis={"calc(50% - 8px)"} type={"date"} label={"Job started at"}
                   placeholder={"Enter job started at"} name={`${name}.jobStartedAt`}/>
      </FormWhere>
    </FormWhere>
  </Box>;
});
