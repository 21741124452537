import { useReactiveVar } from "@apollo/client";
import { useLocation } from "@relcu/react-router";
import { Location } from "@relcu/react-router";
import { useCallback } from "react";
import { useMemo } from "react";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { PHONE_LINE_FRAGMENT } from "../graph/operations.graphql";
import { GET_SETTINGS } from "../graph/operations.graphql";
import { audioDevicesVar } from "../reactiveVars";
import { microphoneAccessVar } from "../reactiveVars";
import { GetCommonPhoneLines } from "./__types__/GetCommonPhoneLines";
import { PhoneLinesUser } from "./__types__/PhoneLinesUser";
import { useSource } from "@relcu/ui";
import { Settings } from "@relcu/date";

interface ViewerPhoneLinesInterface {
  hasMicrophoneIssue: boolean,
  loading: boolean,
  fromNumbers: {
    label: string,
    value: string
  }[],
  bulkFromNumbers: {
    label: string,
    value: string
  }[],
  preferred?: {
    to: {
      smsOptOut?: boolean
      value: string
    }
    from: {
      value: string
    }
  },
  defaultPhoneNumber: {
    label: string,
    value: string
  },
  getLocalNumbers: (toNumbers: Array<{ value: string } | string>) => Array<{
    objectId: string,
    label: string,
    value: string
  }>
  getLocalNumber: (toNumbers?: { value: string } | string) => string
}

export function useViewerPhoneLines(): ViewerPhoneLinesInterface {
  const microphoneAccess = useReactiveVar(microphoneAccessVar);
  const devices = useReactiveVar(audioDevicesVar);
  const {
    data: {
      viewer: {
        user: {
          id,
          defaultPhoneLine,
          localPresence,
          phoneLines: { edges: viewerPhoneLineEdges = [] } = {}
        } = {}
      } = {}
    } = {}
  } = useQuery<PhoneLinesUser>(PHONE_LINES_USER, {
    fetchPolicy: "cache-only"
  });
  const { data: { settings: $settings } } = useQuery(GET_SETTINGS, { fetchPolicy: "cache-only" });

  const { state }: Location<Record<string, any>> = useLocation();
  const preferred = { to: state?.to, from: state?.from };
  const { data: { phoneLines: { edges: phoneLinesEdges = [] } = {} = {} } = {}, loading } = useQuery<GetCommonPhoneLines>(GET_COMMON_PHONE_LINES, {
    fetchPolicy: "cache-only"
  });
  const { data: { phoneLines: { edges: localPresenceEdges = [] } = {} = {} } = {}, loading: presentLoading, error } = useQuery<GetCommonPhoneLines>(GET_LOCAL_PRESENT_PHONE_LINES, {
    fetchPolicy: "cache-only"
  });

  const fromNumbers = useMemo(() => {
    return [...viewerPhoneLineEdges, ...phoneLinesEdges].map(
      ({ node: { number, objectName } }) => ({ label: objectName || number, value: number })
    );
  }, [phoneLinesEdges, id]);

  const localNumbers = useMemo(() => {
    return [...localPresenceEdges].map(
      ({ node: { number, objectName, objectId } }) => ({ label: objectName || number, value: number, objectId })
    );
  }, [localPresenceEdges, id]);
  const bulkFromNumbers = useMemo(() => {
    return viewerPhoneLineEdges.map(
      ({ node: { number, objectName } }) => ({ label: objectName || number, value: number })
    );
  }, [id]);
  const getLocalNumbers = useCallback((toNumbers: Array<{ value: string } | string>) => {
    if ($settings.localPresence?.enabled && localPresence) {
      const areaCodes = toNumbers.map(to => (typeof to == "string" ? to : to.value).match(/^\+1(?<code>\d{3})/)?.groups.code);
      const localLinesObjectIds = $settings.localPresence.mappings.filter(m => m.codes.some(code => areaCodes.includes(code))).map(m => m.lines).flat().map(lines => lines.phoneLine.objectId);
      const numbers = localNumbers.filter(localNumber => localLinesObjectIds.includes(localNumber.objectId));
      if ($settings.localPresence.defaultPhoneLine && !localLinesObjectIds.includes($settings.localPresence.defaultPhoneLine.objectId)) {
        numbers.push(
          {
            objectId: $settings.localPresence.defaultPhoneLine.objectId,
            label: $settings.localPresence.defaultPhoneLine.objectName,
            value: $settings.localPresence.defaultPhoneLine.number
          }
        );
      }
      return numbers
    } else {
      return [];
    }
  }, [localNumbers, $settings, localPresence]);

  const getLocalNumber = useCallback((toNumber?: { value: string } | string) => {
    const localNumbers = getLocalNumbers(toNumber ? [toNumber] : []);
    return localNumbers.at(0)?.value;
  }, [getLocalNumbers]);
  return {
    getLocalNumbers,
    getLocalNumber,
    hasMicrophoneIssue: !microphoneAccess || !devices.audioinput.length || !devices.audiooutput.length,
    fromNumbers: fromNumbers,
    preferred,
    defaultPhoneNumber: fromNumbers.find((from => from.value == defaultPhoneLine?.number)) ?? fromNumbers[ 0 ],
    bulkFromNumbers,
    loading
  };
}
const PHONE_LINES_USER = gql`
  query PhoneLinesUser {
    viewer {
      user {
        id
        localPresence
        defaultPhoneLine {
          number
        }
        phoneLines {
          edges {
            node {
              ...PhoneLine
            }
          }
        }
      }
    }
  }
  ${PHONE_LINE_FRAGMENT}
`;

const GET_COMMON_PHONE_LINES = gql`
  query GetCommonPhoneLines {
    phoneLines(where:{common: {equalTo: true},localPresence: {equalTo: false}} ){
      edges{
        node{
          ...PhoneLine
        }
      }
    }
  }
  ${PHONE_LINE_FRAGMENT}
`;

const GET_LOCAL_PRESENT_PHONE_LINES = gql`
  query GetLocalPresencePhoneLines {
    phoneLines(where:{common: {equalTo: true},localPresence: {equalTo: true}} first:1000 ){
      edges{
        node{
          ...PhoneLine
        }
      }
    }
  }
  ${PHONE_LINE_FRAGMENT}
`;
