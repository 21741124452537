import React                         from "react";
import { JsonSectionProps }          from "@relcu/ui";
import { Box }                       from "@relcu/ui";
import { GRWorkSheetEditSection }    from "./GRWorkSheetEditSection";
import { GRWorkSheetReadSection }    from "./GRWorkSheetReadSection";
import { GRWorkSheetSectionClasses } from "./GRWorkSheetSectionClasses";
import "./gr-worksheet-section.css";

export const GRWorkSheetSection = React.memo<JsonSectionProps>(function GRWorkSheetSection(props) {
  return <Box flex={1} container direction={"column"} gap={"XXS"} style={{ maxWidth: "100%" }} >
    {
      props.view == "write" ?
        <GRWorkSheetEditSection {...props} className={GRWorkSheetSectionClasses.GRWorkSheetSection} /> :
        <GRWorkSheetReadSection {...props} className={GRWorkSheetSectionClasses.GRWorkSheetSection} />
    }
  </Box>;
});

